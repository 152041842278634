import { WidgetsServiceFindAllQueryOptions, WidgetsServiceGroupByOptions } from '@/services'
import { DEFAULT_CURRENCY_CODE } from '@/constants'

export const HOME_PAGE_PRESELECTED_FILTERS: Partial<WidgetsServiceFindAllQueryOptions> = {
    group_by: WidgetsServiceGroupByOptions.Month
}

export const HOME_PAGE_COMMON_WIDGET_PRESELECTED_FILTERS: Partial<WidgetsServiceFindAllQueryOptions> = {
    currency: DEFAULT_CURRENCY_CODE
}

export const HOME_PAGE_STYLE_CONFIGURATION = {
    FONT_SIZE: '12px'
}

export const HOME_PAGE_GRID_CONFIGURATION = {
    MIN_CHILD_WIDTH: 532,
    GRID_ONE_COLUMNS: { sm: 1, md: 1, lg: 2 },
    GRID_TWO_COLUMNS: { sm: 1, md: 1, lg: 2, xl: 2 },
    SPACING: '24px'
}

export const HOME_PAGE_FILTER_BY_AUTO_SUBMIT_DEBOUNCE_TIME = 400
