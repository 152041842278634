import { DetailPageLink, If, LinkWithFilterBy, LinkWithFiltersProps } from '@/components/@misc'
import { useNavigationRoutes } from '@/hooks'
import { Flex } from '@chakra-ui/react'
import {
    ApiObjectTypeSchema,
    DirectDebitMandate,
    DirectDebitMandateDirectionSchema,
    IncomingPaymentsServiceFindAllQueryOptions,
    PaymentOrdersServiceFindAllQueryOptions
} from '@webapps/numeral-ui-core'
import { useMemo } from 'react'

interface DirectDebitMandateDetailsRelatedObjectsProps {
    directDebitMandate: DirectDebitMandate
}

export const DirectDebitMandateDetailsRelatedObjects: React.FC<DirectDebitMandateDetailsRelatedObjectsProps> = ({
    directDebitMandate
}) => {
    const { paths } = useNavigationRoutes()
    const isDirectionOutgoing = useMemo<boolean>(() => {
        return directDebitMandate?.direction === DirectDebitMandateDirectionSchema.enum.outgoing
    }, [directDebitMandate])
    const isDirectionIncoming = useMemo<boolean>(() => {
        return directDebitMandate?.direction === DirectDebitMandateDirectionSchema.enum.incoming
    }, [directDebitMandate])

    const propsRelatedObjects = useMemo<
        LinkWithFiltersProps<IncomingPaymentsServiceFindAllQueryOptions & PaymentOrdersServiceFindAllQueryOptions>
    >(() => {
        const type = isDirectionOutgoing
            ? ApiObjectTypeSchema.enum.payment_order
            : isDirectionIncoming
              ? ApiObjectTypeSchema.enum.incoming_payment
              : ''
        const path = isDirectionOutgoing
            ? paths.PAYMENTS.PAYMENT_ORDERS
            : isDirectionIncoming
              ? paths.PAYMENTS.INCOMING_PAYMENTS
              : paths.NOT_FOUND

        return {
            labelKey: `app.counterparties.direct_debit_mandates.details.related_objects.${type}.label`,
            path,
            filterBy: {
                direct_debit_mandate_id: directDebitMandate?.id
            }
        }
    }, [directDebitMandate, paths])

    return (
        <Flex direction="column" gap="8px">
            <If condition={!!directDebitMandate.originating_account_id}>
                <DetailPageLink
                    objectId={directDebitMandate.originating_account_id}
                    objectType={ApiObjectTypeSchema.enum.internal_account}
                />
            </If>

            <If condition={!!directDebitMandate.receiving_account_id}>
                <DetailPageLink
                    objectId={directDebitMandate.receiving_account_id}
                    objectType={ApiObjectTypeSchema.enum.external_account}
                />
            </If>

            <If condition={!!isDirectionOutgoing}>
                <LinkWithFilterBy<PaymentOrdersServiceFindAllQueryOptions> {...propsRelatedObjects} />
            </If>

            <If condition={!!isDirectionIncoming}>
                <LinkWithFilterBy<IncomingPaymentsServiceFindAllQueryOptions> {...propsRelatedObjects} />
            </If>
        </Flex>
    )
}
