import { memo, useMemo } from 'react'
import { ApiObjectType, ApiObjectTypeSchema, ReconciliationStatusSchema } from '@webapps/numeral-ui-core'
import { HOME_PAGE_STYLE_CONFIGURATION } from '@/pages/Home/Home.page.const'
import { LinkWithFilterBy, Separator, SeparatorVariant } from '@/components/@misc'
import { useIntl } from 'react-intl'
import { format, startOfToday, subDays } from 'date-fns'
import { DEFAULT_DATE_TEMPLATE_FORMAT, EMPTY_CHAR_SYMBOL } from '@/constants'
import { WidgetsServiceLookBehindOffsets } from '@/services'
import { NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS } from '@/providers'
import { Flex, Text } from '@chakra-ui/react'
import { percentageFormatter } from '@/utils/@formatters'

interface TableCellReconciledStatusLinkProps {
    objectType: ApiObjectType
    reconciledRate: number
    totalReconciledRate: number
}

export function getNavigationRouteByObjectType(objectType: ApiObjectType) {
    const { PAYMENTS, ACCOUNTS } = NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS

    switch (objectType) {
        case ApiObjectTypeSchema.enum.payment_order: {
            return PAYMENTS.PAYMENT_ORDERS
        }

        case ApiObjectTypeSchema.enum.incoming_payment: {
            return PAYMENTS.INCOMING_PAYMENTS
        }

        case ApiObjectTypeSchema.enum.expected_payment: {
            return PAYMENTS.EXPECTED_PAYMENTS
        }

        case ApiObjectTypeSchema.enum.return: {
            return PAYMENTS.RETURNS
        }

        case ApiObjectTypeSchema.enum.payment_capture: {
            return PAYMENTS.PAYMENT_CAPTURES
        }

        case ApiObjectTypeSchema.enum.transaction: {
            return ACCOUNTS.TRANSACTIONS
        }

        default: {
            return EMPTY_CHAR_SYMBOL
        }
    }
}

export const TableCellReconciledStatusLink: React.FC<TableCellReconciledStatusLinkProps> = memo(
    ({ objectType, reconciledRate, totalReconciledRate }) => {
        const intl = useIntl()
        const filterByState = useMemo(() => {
            const today = startOfToday()
            const startDate: Date = subDays(today, WidgetsServiceLookBehindOffsets.SIX_MONTHS)
            const formattedStartDate = format(startDate, DEFAULT_DATE_TEMPLATE_FORMAT)

            return {
                reconciliation_status: ReconciliationStatusSchema.enum.reconciled,
                start_date: formattedStartDate
            }
        }, [])
        const path = useMemo(() => {
            return getNavigationRouteByObjectType(objectType)
        }, [objectType])
        const formattedReconciledRate = useMemo(() => {
            return percentageFormatter(reconciledRate, true)
        }, [reconciledRate])
        const formattedTotalReconciledRate = useMemo(() => {
            return intl.formatNumber(totalReconciledRate)
        }, [totalReconciledRate])

        return (
            <Flex fontSize={HOME_PAGE_STYLE_CONFIGURATION.FONT_SIZE}>
                <LinkWithFilterBy<typeof filterByState>
                    filterBy={filterByState}
                    path={path}
                    title={formattedTotalReconciledRate}
                    aria-label={formattedTotalReconciledRate}>
                    {formattedTotalReconciledRate}
                </LinkWithFilterBy>
                <Separator variant={SeparatorVariant.Bullet} />
                <Text color="gray.500" title={formattedReconciledRate} aria-label={formattedReconciledRate}>
                    {formattedReconciledRate}
                </Text>
            </Flex>
        )
    }
)
