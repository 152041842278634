import { QueryFallback, RestrictedSection, Table, useTableRowNavigation } from '@/components'
import { useQueryFindAllRoles } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION, Role } from '@/services'
import { queryDataAggregation } from '@/utils'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { ROLES_TABLE_COLUMN_CUSTOMIZERS, ROLES_TABLE_STATIC_STATE } from './Roles.page.const'

export const RolesPage: React.FC = () => {
    const query = useQueryFindAllRoles()
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const onTableRowClick = useTableRowNavigation<Role>('id')

    return (
        <PageLayout className="Roles">
            <RestrictedSection permission={PERMISSION.SETTINGS_VIEW_ROLES}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.role}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isDataEmpty}>
                    <Table<Role>
                        data={data}
                        onRowClick={onTableRowClick}
                        onScrollToBottom={query.fetchNextPage}
                        isLoading={query.hasNextPage}
                        columnCustomizers={ROLES_TABLE_COLUMN_CUSTOMIZERS}
                        state={ROLES_TABLE_STATIC_STATE}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
