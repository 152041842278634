import { PaymentType, PaymentTypeSchema } from '@webapps/numeral-ui-core'
import { NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS } from '@/providers'
import { PAYMENT_COUNTERS_WIDGET_COLUMN_CUSTOMIZERS } from './PaymentCountersWidget.const'
import { TableColumnCustomizers } from '@/components'
import { PaymentCounters, WidgetsServiceLookBehindOffsets } from '@/services'
import { format, startOfToday, subDays } from 'date-fns'
import { LinkWithFilterBy } from '@/components/@misc'
import { DEFAULT_DATE_TEMPLATE_FORMAT, EMPTY_CHAR_SYMBOL } from '@/constants'
import {
    PaymentCountersFindAllQueryOptionsMap,
    PaymentCountersStatusQueryOptionsMap
} from './PaymentCountersWidget.types'
import { ExtractType } from '@/types'
import { formatNumber } from 'chart.js/helpers'
import { IntlShape } from 'react-intl'
import { HOME_PAGE_STYLE_CONFIGURATION } from '@/pages/Home/Home.page.const'

export function computePaymentCountersFilterByOptions(
    paymentType: PaymentType,
    status: ExtractType<typeof paymentType, PaymentCountersStatusQueryOptionsMap>,
    offset: WidgetsServiceLookBehindOffsets = 0
): ExtractType<typeof paymentType, PaymentCountersFindAllQueryOptionsMap> {
    const today = startOfToday()
    const startDate: Date = offset === 0 ? today : subDays(today, offset)

    const formattedStartDate = format(startDate, DEFAULT_DATE_TEMPLATE_FORMAT)
    const formattedEndDate = format(today, DEFAULT_DATE_TEMPLATE_FORMAT)

    return {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        status
    }
}

export function getPaymentCountersWidgetColumnCustomizers(
    paymentType: PaymentType,
    intl: IntlShape
): TableColumnCustomizers<PaymentCounters> {
    const customizers = { ...PAYMENT_COUNTERS_WIDGET_COLUMN_CUSTOMIZERS }
    const path = getNavigationRouteByPaymentType(paymentType)

    customizers.last_seven_days = {
        size: 0.1,
        cell(info) {
            const { status } = info.row.original
            const labelKey = intl?.formatNumber(info.getValue())
            const filterByState = computePaymentCountersFilterByOptions(
                paymentType,
                status,
                WidgetsServiceLookBehindOffsets.SEVEN_DAYS
            )
            return (
                <LinkWithFilterBy<typeof filterByState>
                    filterBy={filterByState}
                    labelKey={labelKey}
                    path={path}
                    aria-label={labelKey}
                    title={labelKey}
                    fontSize={HOME_PAGE_STYLE_CONFIGURATION.FONT_SIZE}
                />
            )
        }
    }

    customizers.today = {
        size: 0.1,
        cell(info) {
            const { status } = info.row.original
            const labelKey = formatNumber(info.getValue(), intl.locale)
            const filterBy = computePaymentCountersFilterByOptions(paymentType, status)
            return (
                <LinkWithFilterBy<typeof filterBy>
                    filterBy={filterBy}
                    labelKey={labelKey}
                    path={path}
                    fontSize={HOME_PAGE_STYLE_CONFIGURATION.FONT_SIZE}
                />
            )
        }
    }

    return customizers
}

export function getNavigationRouteByPaymentType(paymentType: PaymentType) {
    const { PAYMENTS } = NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS

    switch (paymentType) {
        case PaymentTypeSchema.enum.payment_order: {
            return PAYMENTS.PAYMENT_ORDERS
        }

        case PaymentTypeSchema.enum.incoming_payment: {
            return PAYMENTS.INCOMING_PAYMENTS
        }

        case PaymentTypeSchema.enum.return: {
            return PAYMENTS.RETURNS
        }

        default: {
            return EMPTY_CHAR_SYMBOL
        }
    }
}
