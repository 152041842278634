import { chain } from 'lodash'
import { PERCENTAGE_SYMBOL } from '@/constants'

const percentageTempleExecutor = chain('${value}' + PERCENTAGE_SYMBOL)
    .template()
    .value()

const PERCENTAGE_DECIMALS_ROUNDED = 0
const PERCENTAGE_DECIMALS_NOT_ROUNDED = 2

export function percentageFormatter(value?: number | string, isRounded: boolean = false): string {
    const valueWrapperWithDefault = chain(value).toNumber().defaultTo(0)
    const formattedValue = isRounded
        ? valueWrapperWithDefault.round(PERCENTAGE_DECIMALS_ROUNDED).value()
        : valueWrapperWithDefault.value().toFixed(PERCENTAGE_DECIMALS_NOT_ROUNDED)

    return percentageTempleExecutor({
        value: formattedValue
    })
}
