import { APIQueryParamStateFilterBy, usePage, usePersistedLocationState } from '@/hooks'
import { RECONCILE_ENTITY_PAGE_CONTEXT, useReconciliation } from '@/pages'
import { Payment } from '@/types'
import { Transaction } from '@webapps/numeral-ui-core'
import { chain, isEmpty } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { PERSISTED_STORE_QUICK_FILTER_STATE } from './QuickFilterProvider.const'
import { QuickFilterContext } from './QuickFilterProvider.context'
import { QuickFilterParamTypes, QuickFilterProviderProps } from './QuickFilterProvider.types'

export function QuickFilterProvider<T>({ children, filterBy }: QuickFilterProviderProps<T>) {
    const { getNonPersistedState } = usePage<Payment | Transaction>()
    const reconcileEntityNonPersistedState = getNonPersistedState(RECONCILE_ENTITY_PAGE_CONTEXT)
    const { targetQuickFilter, onCancelReconciliation } = useReconciliation()
    const [persistence, setPersistence] = usePersistedLocationState<APIQueryParamStateFilterBy<T>>(
        PERSISTED_STORE_QUICK_FILTER_STATE,
        QuickFilterParamTypes.QuickFilter
    )
    const initialState = useMemo<typeof persistence>(() => {
        const hasPreselectedFilterBy = !isEmpty(filterBy.configuration?.preselected)
        const hasNoPersistence = isEmpty(persistence)

        switch (true) {
            case hasNoPersistence && hasPreselectedFilterBy: {
                return filterBy.configuration?.preselected
            }

            default: {
                return persistence
            }
        }
    }, [persistence, filterBy.configuration])

    const [state, setState] = useState<typeof initialState>(initialState)
    const onChange = useCallback(
        (newValue?: typeof persistence) => {
            const isNewValueUnreconciledQuickFilterState = chain(newValue).isEqual(targetQuickFilter).value()

            if (reconcileEntityNonPersistedState && !isNewValueUnreconciledQuickFilterState) {
                onCancelReconciliation()
            }

            setState(newValue)
            setPersistence(newValue)

            filterBy.setState(newValue)
        },
        [filterBy, targetQuickFilter, reconcileEntityNonPersistedState]
    )
    const onReset = useCallback(() => onChange(undefined), [onChange])

    useEffect(() => {
        const hasPreselectedFilterBy = !isEmpty(filterBy.configuration?.preselected)

        if (hasPreselectedFilterBy) {
            onChange(filterBy.configuration?.preselected)
        }
    }, [filterBy.configuration?.preselected])

    return (
        <QuickFilterContext.Provider
            value={{
                quickFilters: filterBy?.configuration?.quickFilters,
                state,
                onChange,
                onReset
            }}>
            {children}
        </QuickFilterContext.Provider>
    )
}
