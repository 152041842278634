import {
    FilterInputs,
    TableCellAccount,
    TableCellAmount,
    TableCellShortDate,
    TableColumnCustomizers,
    TableStateWithEntity
} from '@/components'
import {
    AmountInput,
    BankNameInput,
    ConnectedAccountsInput,
    CurrencyInput,
    DateInput,
    ExternalAccountsInput,
    InternalAccountInput,
    PaymentDirectionInput,
    ReconciliationStatusInput,
    TextInput,
    TransactionCategoryInput
} from '@/components/@inputs'
import { ConnectedAccountName, Direction, ReconciliationStatusLabel } from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, DOCUMENTATION_BASE_URL, HIGHLIGHTED_CURRENCY_CODES, MIN_COLUMN_WIDTH } from '@/constants'
import { APIQueryParamQuickFilters } from '@/hooks'
import { uuidValidator } from '@/utils/@validators'
import {
    ReconciliationStatusSchema,
    Transaction,
    TransactionsServiceFindAllQueryOptions
} from '@webapps/numeral-ui-core'

export const TRANSACTION_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/transaction-object`

export const TRANSACTION_TABLE_STATIC_STATE: TableStateWithEntity<Transaction> = {
    columnOrder: [
        'value_date',
        'booking_date',
        'connected_account_id',
        'direction',
        'amount',
        'external_account',
        'reconciliation_status'
    ]
}

export const TRANSACTION_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<Transaction> = {
    value_date: {
        size: COLUMN_WIDTH_RATIO.SHORT_DATE,
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    booking_date: {
        size: COLUMN_WIDTH_RATIO.SHORT_DATE,
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    connected_account_id: {
        size: COLUMN_WIDTH_RATIO.CONNECTED_ACCOUNT,
        cell(info) {
            return <ConnectedAccountName connectedAccountId={info.getValue()} asPlainText={true} />
        }
    },
    direction: {
        size: COLUMN_WIDTH_RATIO.DIRECTION,
        cell(info) {
            return <Direction value={info.getValue()} />
        }
    },
    amount: {
        size: COLUMN_WIDTH_RATIO.AMOUNT,
        minSize: MIN_COLUMN_WIDTH.AMOUNT,
        cell(info) {
            const { currency } = info.row.original

            return <TableCellAmount amount={info.getValue()} currency={currency} />
        }
    },
    external_account: {
        size: COLUMN_WIDTH_RATIO.ACCOUNT,
        cell(info) {
            return <TableCellAccount value={info.getValue()} />
        }
    },
    reconciliation_status: {
        minSize: MIN_COLUMN_WIDTH.RECONCILIATION_STATUS,
        cell(info) {
            return <ReconciliationStatusLabel value={info.getValue()} />
        }
    }
}

export const TRANSACTIONS_FILTERS: FilterInputs<TransactionsServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    start_value_date: <DateInput name="start_value_date" />,
    end_value_date: <DateInput name="end_value_date" />,
    start_booking_date: <DateInput name="start_booking_date" />,
    end_booking_date: <DateInput name="end_booking_date" />,
    direction: <PaymentDirectionInput name="direction" isPlaceholderDisplayed={true} />,
    category: <TransactionCategoryInput name="category" isPlaceholderDisplayed={true} />,
    currency: <CurrencyInput name="currency" highlightedCurrencyCodes={HIGHLIGHTED_CURRENCY_CODES} />,
    amount_from: <AmountInput name="amount_from" />,
    amount_to: <AmountInput name="amount_to" />,
    internal_account_id: <InternalAccountInput name="internal_account_id" />,
    internal_account_number: <TextInput name="internal_account_number" />,
    bank_name: <BankNameInput name="bank_name" isPlaceholderDisplayed={true} />,
    connected_account_id: <ConnectedAccountsInput name="connected_account_id" isPlaceholderDisplayed={true} />,
    external_account_id: <ExternalAccountsInput name="external_account_id" isPlaceholderDisplayed={true} />, //TODO waiting for a FIX API side
    external_account_number: <TextInput name="external_account_number" />,
    external_account_holder_name: <TextInput name="external_account_holder_name" />,
    file_id: <TextInput name="file_id" validator={uuidValidator} />,
    reconciliation_status: <ReconciliationStatusInput name="reconciliation_status" />
}

export const TRANSACTIONS_QUICK_FILTERS: APIQueryParamQuickFilters<TransactionsServiceFindAllQueryOptions> = {
    reconciliation_status: [ReconciliationStatusSchema.enum.unreconciled]
}
