import { QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdRole } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { RoleDetailsContent, RoleDetailsHeader } from './@components'

export const RoleDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdRole(uuid)

    return (
        <PageLayout className="RoleDetails">
            <RestrictedSection permission={PERMISSION.SETTINGS_VIEW_ROLES}>
                <RoleDetailsHeader />
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.role}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <RoleDetailsContent data={query.data} />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
