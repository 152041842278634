import { TableColumnCustomizers, TableStateWithEntity } from '@/components'
import { Text } from '@chakra-ui/react'
import { EventsServiceFindAllQueryOptions, NumeralEvent } from '@webapps/numeral-ui-core'
import { DOT_SYMBOL } from '@/constants'
import { TableCellLatestEventCreatedAt } from './@components'

export const LATEST_EVENTS_WIDGET_PRESELECTED_FILTERS: Partial<EventsServiceFindAllQueryOptions> = {
    limit: 7
}

export const LATEST_EVENTS_WIDGET_TABLE_STATIC_STATE: TableStateWithEntity<NumeralEvent> = {
    columnOrder: ['topic', 'created_at']
}

export const LATEST_EVENTS_WIDGET_COLUMN_CUSTOMIZERS: TableColumnCustomizers<NumeralEvent> = {
    topic: {
        header() {
            return null
        },
        cell(info) {
            const { type } = info.row.original
            const formattedValue = [info.getValue(), type].join(DOT_SYMBOL).toString()
            return (
                <Text color="gray.500" fontSize="12px">
                    {formattedValue}
                </Text>
            )
        }
    },
    created_at: {
        size: 0.3,
        cell(info) {
            const { id, created_at } = info.row.original
            return <TableCellLatestEventCreatedAt id={id} createdAt={created_at} />
        }
    }
}
