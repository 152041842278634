import { Widget, WidgetQueryFallback } from '@/components'
import { If } from '@/components/@misc'
import { useAPIQueryParams, usePermissions, useQueryReconciliations } from '@/hooks'
import {
    PERMISSION,
    ReconciliationItem,
    WIDGET_TYPE,
    WidgetsServiceFindAllQueryOptions,
    WidgetsServiceReconciliationQueryOptions
} from '@/services'
import { percentageFormatter } from '@/utils/@formatters'
import { Stack, Text } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { WidgetLiteTable } from '../WidgetLiteTable'
import {
    RECONCILIATIONS_WIDGET_COLUMN_CUSTOMIZERS,
    RECONCILIATIONS_WIDGET_TABLE_STATIC_STATE
} from './ReconciliationsWidget.const'

export const ReconciliationsWidget: React.FC<WidgetsServiceFindAllQueryOptions> = (props) => {
    const intl = useIntl()
    const { filterBy } = useAPIQueryParams<WidgetsServiceReconciliationQueryOptions>({
        filterBy: {
            configuration: {
                preselected: {
                    ...props
                },
                uniqueId: WIDGET_TYPE.Reconciliations
            }
        }
    })
    const query = useQueryReconciliations(filterBy.parsedState)
    const isDataEmpty = useMemo(() => {
        return isEmpty(query.data?.records)
    }, [query])
    const title = useMemo(
        () =>
            intl.formatMessage({
                id: 'app.widgets.reconciliations.title'
            }),
        [intl]
    )
    const { hasPermission } = usePermissions()
    const hasViewReconciliationsPermission = useMemo(() => {
        return hasPermission(PERMISSION.HOME_VIEW_RECONCILIATIONS)
    }, [hasPermission])
    const formattedAutomationRate = useMemo(() => {
        return percentageFormatter(query.data?.automation_rate, true)
    }, [query.data])

    return (
        <If condition={hasViewReconciliationsPermission}>
            <Widget title={title}>
                <WidgetQueryFallback isLoading={query.isLoading} isDataEmpty={isDataEmpty}>
                    <Stack>
                        <Stack gap="8px">
                            <Text fontSize="12px" lineHeight="16px" color="gray.500">
                                <FormattedMessage id="app.widgets.reconciliations.automation_rate" />
                            </Text>
                            <Text fontSize="24px" lineHeight="32px" color="gray.800">
                                {formattedAutomationRate}
                            </Text>
                        </Stack>
                        <WidgetLiteTable<ReconciliationItem>
                            state={RECONCILIATIONS_WIDGET_TABLE_STATIC_STATE}
                            columnCustomizers={RECONCILIATIONS_WIDGET_COLUMN_CUSTOMIZERS}
                            data={query.data?.records}
                        />
                    </Stack>
                </WidgetQueryFallback>
            </Widget>
        </If>
    )
}
