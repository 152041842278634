import { TableStateWithEntity } from '@/components'
import { AuditTrail, AuditTrailsServiceFindAllQueryOptions } from '@/services'
import { AuditTrailFilterByOption } from './AuditTrail.page.types'

export function getAuditTrailTableStaticState(filterBy?: AuditTrailFilterByOption): TableStateWithEntity<AuditTrail> {
    const columnOrder: Array<keyof AuditTrail> = ['created_at', 'public_name', 'author_type']

    if (!filterBy || filterBy === AuditTrailFilterByOption.OBJECT) {
        columnOrder.push('author_name')
    }

    if (!filterBy || filterBy === AuditTrailFilterByOption.AUTHOR) {
        columnOrder.push('object_id')
    }

    return { columnOrder }
}

export function getAuditTrailEmptyStateDescriptionKey(filterBy?: AuditTrailFilterByOption): string | undefined {
    if (!filterBy) {
        return
    }

    if (filterBy === AuditTrailFilterByOption.OBJECT) {
        return 'app.common.page.audit_trail.filtered_by_object.empty_state.description'
    }

    if (filterBy === AuditTrailFilterByOption.AUTHOR) {
        return 'app.common.page.audit_trail.filtered_by_author.empty_state.description'
    }
}

export function getAuditTrailAPIQueryParams(
    filterBy: AuditTrailFilterByOption | undefined,
    id: string | undefined
): Partial<AuditTrailsServiceFindAllQueryOptions> {
    if (!filterBy || !id) {
        return {}
    }
    return { [filterBy]: id }
}
