import { Widget, WidgetQueryFallback } from '@/components'
import { If } from '@/components/@misc'
import { useAPIQueryParams, usePermissions, useQueryPaymentCounters } from '@/hooks'
import {
    filterPaymentCountersByPaymentType,
    PaymentCounters,
    PERMISSION,
    WIDGET_TYPE,
    WidgetsServiceFindAllQueryOptions,
    WidgetsServicePaymentCountersQueryOptions
} from '@/services'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { WidgetLiteTable } from '../WidgetLiteTable'
import { PaymentCountersWidgetTitle } from './@components'
import {
    PAYMENT_COUNTERS_PRESELECTED_FILTERS,
    PAYMENT_COUNTERS_WIDGET_TABLE_STATIC_STATE
} from './PaymentCountersWidget.const'
import { getPaymentCountersWidgetColumnCustomizers } from './PaymentCountersWidget.utils'

export const PaymentCountersWidget: React.FC<WidgetsServiceFindAllQueryOptions> = (props) => {
    const intl = useIntl()
    const { filterBy } = useAPIQueryParams<WidgetsServicePaymentCountersQueryOptions>({
        filterBy: {
            configuration: {
                preselected: PAYMENT_COUNTERS_PRESELECTED_FILTERS,
                uniqueId: WIDGET_TYPE.PaymentCounters
            }
        }
    })
    const query = useQueryPaymentCounters({
        ...props,
        ...filterBy.parsedState
    })
    const columnCustomizers = useMemo(() => {
        return getPaymentCountersWidgetColumnCustomizers(filterBy.parsedState.payment_type, intl)
    }, [filterBy, intl])
    const filteredData = useMemo(
        () => filterPaymentCountersByPaymentType(filterBy.parsedState.payment_type, query.data?.records),
        [filterBy, query.data?.records]
    )
    const isDataEmpty = useMemo(() => isEmpty(filteredData), [filteredData])
    const { hasPermission } = usePermissions()
    const hasViewPaymentCountersPermission = useMemo(() => {
        return hasPermission(PERMISSION.HOME_VIEW_PAYMENT_COUNTERS)
    }, [hasPermission])

    return (
        <If condition={hasViewPaymentCountersPermission}>
            <Widget title={<PaymentCountersWidgetTitle filterBy={filterBy} />}>
                <WidgetQueryFallback isLoading={query.isLoading} isError={query.isError} isDataEmpty={isDataEmpty}>
                    <WidgetLiteTable<PaymentCounters>
                        state={PAYMENT_COUNTERS_WIDGET_TABLE_STATIC_STATE}
                        columnCustomizers={columnCustomizers}
                        data={filteredData}
                    />
                </WidgetQueryFallback>
            </Widget>
        </If>
    )
}
