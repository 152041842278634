import { SupportedApiEnvironmentName } from '@/configuration'
import { User } from '@/services'
import { UserInfo, Uuid } from '@webapps/numeral-ui-core'
import { USER_IDENTITY_PROVIDERS_PATTERN } from './User.service.const'
import { UserAccess } from './User.service.types'

export function filterUserAccessAPIQueryParams(userAccess?: UserAccess): { access_id?: Uuid } {
    return { access_id: userAccess?.id }
}

export function isProductionUserAccessEnv(userAccess?: UserAccess) {
    return userAccess?.environment === SupportedApiEnvironmentName.PRODUCTION
}

export function isSandboxUserAccessEnv(userAccess?: UserAccess) {
    return userAccess?.environment === SupportedApiEnvironmentName.SANDBOX
}

export function isUserLoggedInWithIdentityProvider(id?: string): boolean {
    if (!id) {
        return false
    }
    return USER_IDENTITY_PROVIDERS_PATTERN.test(id)
}

export function isCurrentUser(userInfo?: UserInfo, user?: User): boolean {
    return userInfo?.email === user?.email
}
