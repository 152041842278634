import { PaymentCounters } from '@/services'
import {
    IncomingPaymentStatusSchema,
    PaymentOrderStatusSchema,
    PaymentType,
    PaymentTypeSchema,
    ReturnStatusSchema
} from '@webapps/numeral-ui-core'
import { chain, includes } from 'lodash'

export function filterPaymentCountersByPaymentType(paymentType: PaymentType, records?: PaymentCounters[]) {
    return chain(records)
        .filter((record) => {
            switch (paymentType) {
                case PaymentTypeSchema.enum.payment_order:
                    return includes(
                        [
                            PaymentOrderStatusSchema.enum.pending_approval,
                            PaymentOrderStatusSchema.enum.approved,
                            PaymentOrderStatusSchema.enum.processing,
                            PaymentOrderStatusSchema.enum.sent,
                            PaymentOrderStatusSchema.enum.executed,
                            PaymentOrderStatusSchema.enum.returned
                        ],
                        record.status
                    )

                case PaymentTypeSchema.enum.incoming_payment:
                    return includes(
                        [IncomingPaymentStatusSchema.enum.pending_return, IncomingPaymentStatusSchema.enum.rejected],
                        record.status
                    )

                case PaymentTypeSchema.enum.return:
                    return includes([ReturnStatusSchema.enum.pending_approval], record.status)

                default:
                    return includes(
                        [
                            PaymentOrderStatusSchema.enum.returned,
                            PaymentOrderStatusSchema.enum.approved,
                            PaymentOrderStatusSchema.enum.sent
                        ],
                        record.status
                    )
            }
        })
        .value()
}
