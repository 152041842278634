import { AccountsIcon } from '@/components/@icons'
import { ParentPageLayout } from '@/layouts'
import {
    AuditTrailFilterByOption,
    AuditTrailPage,
    BalanceDetailsHeader,
    BalanceDetailsPage,
    BalancesPage,
    EventsPage,
    TransactionCounterpartiesPage,
    TransactionDetailsPage,
    TransactionEditPage,
    TransactionHeader,
    TransactionReconcilePage,
    TransactionsPage,
    TransactionsReconciliationsPage
} from '@/pages'
import { getNestedRoutesWithTabLayout, NavigationRoute, NotFoundRoute } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { Navigate } from 'react-router-dom'
import { NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS } from '../NavigationRoutesProvider.const'

export function getAccountsNavigationRoutes(): NavigationRoute {
    const { EDIT, UUID, ACCOUNTS, RECONCILE } = NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS

    const transactionRoutes = [
        {
            title: 'app.accounts.tabs.details.title',
            path: ACCOUNTS.DETAILS,
            element: <TransactionDetailsPage />
        },
        {
            title: 'app.accounts.tabs.counterparties.title',
            path: ACCOUNTS.COUNTERPARTIES,
            element: <TransactionCounterpartiesPage />
        },
        {
            title: 'app.accounts.tabs.reconciliations.title',
            path: ACCOUNTS.RECONCILIATIONS,
            element: <TransactionsReconciliationsPage />
        },
        {
            title: 'app.payments.tabs.events.title',
            path: ACCOUNTS.EVENTS,
            element: <EventsPage />
        },
        {
            title: 'app.accounts.tabs.audit_trail.title',
            path: ACCOUNTS.AUDIT_TRAIL,
            element: (
                <AuditTrailPage
                    objectType={ApiObjectTypeExtendedSchema.enum.transaction}
                    filterBy={AuditTrailFilterByOption.OBJECT}
                />
            )
        }
    ]
    const balancesRoutes = [
        {
            title: 'app.accounts.tabs.details.title',
            path: ACCOUNTS.DETAILS,
            element: <BalanceDetailsPage />
        },
        {
            title: 'app.accounts.tabs.audit_trail.title',
            path: ACCOUNTS.AUDIT_TRAIL,
            element: (
                <AuditTrailPage
                    objectType={ApiObjectTypeExtendedSchema.enum.balance}
                    filterBy={AuditTrailFilterByOption.OBJECT}
                />
            )
        }
    ]

    return {
        title: 'app.accounts.title',
        path: '/accounts',
        configuration: {
            isGroup: true
        },
        icon: <AccountsIcon />,
        routes: [
            { index: true, element: <Navigate replace={true} to="balances" /> },
            {
                title: 'app.accounts.balances.title',
                path: 'balances',
                element: <ParentPageLayout element={<BalancesPage />} />,
                routes: getNestedRoutesWithTabLayout(UUID, balancesRoutes, {
                    header: <BalanceDetailsHeader />
                })
            },
            {
                title: 'app.accounts.transactions.title',
                path: 'transactions',
                element: <ParentPageLayout element={<TransactionsPage />} />,
                routes: [
                    {
                        title: 'app.accounts.transactions.reconcile.title',
                        path: RECONCILE,
                        element: <TransactionReconcilePage />
                    },
                    {
                        title: 'app.accounts.transactions.edit.title',
                        path: EDIT,
                        element: <TransactionEditPage />
                    },
                    ...getNestedRoutesWithTabLayout(UUID, transactionRoutes, {
                        header: <TransactionHeader />
                    })
                ]
            },
            NotFoundRoute
        ]
    }
}
