import { TableColumnCustomizers, TableStateWithEntity } from '@/components'
import { ReconciliationItem } from '@/services'
import { Text } from '@chakra-ui/react'
import { prettyPrintCasedWords } from '@/utils'
import { TableCellReconciledStatusLink } from './@components'
import { HOME_PAGE_STYLE_CONFIGURATION } from '../../Home.page.const'
import { FormattedMessage } from 'react-intl'

export const RECONCILIATIONS_WIDGET_TABLE_STATIC_STATE: TableStateWithEntity<ReconciliationItem> = {
    columnOrder: ['object', 'total_reconciled']
}

export const RECONCILIATIONS_WIDGET_COLUMN_CUSTOMIZERS: TableColumnCustomizers<ReconciliationItem> = {
    object: {
        header() {
            return null
        },
        cell(info) {
            return (
                <Text color="gray.500" fontSize={HOME_PAGE_STYLE_CONFIGURATION.FONT_SIZE}>
                    <FormattedMessage
                        id="app.common.pluralization.label"
                        values={{
                            count: 2,
                            singular: prettyPrintCasedWords(info.getValue(), true)
                        }}
                    />
                </Text>
            )
        }
    },
    total_reconciled: {
        size: 0.1,
        cell(info) {
            const { reconciled_rate, object } = info.row.original
            return (
                <TableCellReconciledStatusLink
                    objectType={object}
                    reconciledRate={reconciled_rate}
                    totalReconciledRate={info.getValue()}
                />
            )
        }
    }
}
