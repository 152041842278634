import { useQueryFindAllUsers } from '@/hooks'
import { getUserFullName } from '@/services'
import { queryDataAggregation } from '@/utils'
import { Box, FormControl, FormLabel, SkeletonText } from '@chakra-ui/react'
import { Uuid } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

interface RoleDetailsAssignedUsersProps {
    roleId?: Uuid
}

export const RoleDetailsAssignedUsers: React.FC<RoleDetailsAssignedUsersProps> = ({ roleId }) => {
    const query = useQueryFindAllUsers(
        { role_id: roleId },
        {
            enabled: globalThis.Boolean(roleId)
        }
    )
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const content = query.isLoading ? (
        <SkeletonText noOfLines={2} skeletonHeight="4" width="50%" />
    ) : (
        data.map((user, index) => {
            return (
                <Box as="span" key={user.id}>
                    {getUserFullName(user, user.email)}
                </Box>
            )
        })
    )

    return (
        <FormControl>
            <FormLabel marginBottom="16px">
                <FormattedMessage id="app.settings.roles.details.assigned_users.label" />
            </FormLabel>
            {(() => {
                switch (true) {
                    case !query.isLoading && isDataEmpty:
                    case query.isError: {
                        return <FormattedMessage id="app.settings.roles.details.assigned_users.content.none" />
                    }

                    default: {
                        return (
                            <Box
                                display="flex"
                                gap="16px"
                                flexDirection="column"
                                padding="16px"
                                borderWidth="1px"
                                borderColor="gray.100"
                                borderRadius="var(--numeral-ui-primary-border-radius)"
                                key={roleId}>
                                {content}
                            </Box>
                        )
                    }
                }
            })()}
        </FormControl>
    )
}
